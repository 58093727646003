<template>
  <div class="bootstrap snippets bootdeys">
    <div class="row">
      <div class="col-sm-12">

        <div class="panel panel-default invoice" id="invoice">
          <div v-if="isLoading">
            Loading payment details...
          </div>
          <div class="panel-body" v-else-if="payment">

            <div class="invoice-ribbon">
              <div class="ribbon-inner">{{ payment.status.label }}</div>
            </div>
            <div class="row">
              <div class="col-sm-6 top-left">
              </div>
              <div class="col-sm-6 top-right">
                <h3 class="marginright">Payment Receipt {{ payment.id }}</h3>
                <span class="marginright">{{ payment.created_at | formatDate }}</span>
              </div>
            </div>
            <hr>
            <div class="row">

              <div class="col-4 col-xs-4 from">
                <p class="lead marginbottom">Order : {{ payment.user.name }}</p>
              </div>

<!--              <div class="col-4 col-xs-4 to">-->
<!--&lt;!&ndash;                <p class="lead marginbottom">To : {{ order.shippingAddress.contact_name }}</p>&ndash;&gt;-->
<!--&lt;!&ndash;                <p>{{ order.shippingAddress.name }}</p>&ndash;&gt;-->
<!--&lt;!&ndash;                <p>{{ order.shippingAddress.street }}</p>&ndash;&gt;-->
<!--&lt;!&ndash;                <p>{{ order.shippingAddress.city }}</p>&ndash;&gt;-->
<!--&lt;!&ndash;                <p>{{ order.shippingAddress.province }}</p>&ndash;&gt;-->
<!--&lt;!&ndash;                <p>{{ order.shippingAddress.country }}</p>&ndash;&gt;-->
<!--&lt;!&ndash;                <p>Phone: {{ order.shippingAddress.phone_number }}</p>&ndash;&gt;-->
<!--&lt;!&ndash;                <p>Email: {{ order.shippingAddress.email }}</p>&ndash;&gt;-->
<!--              </div>-->
<!--              <div class="col-4 col-xs-4 to" v-else>-->
                <p class="lead marginbottom">To : -</p>
<!--              </div>-->

              <div class="col-4 col-xs-4 text-right payment-details">
<!--                <p class="lead marginbottom payment-info">Payment details</p>-->
<!--                <p v-if="isPaid">Date: {{ order.paid_at | formatDate }}</p>-->
<!--                <p>Total Amount: {{ order.total_amount | toCurrency }}</p>-->
              </div>

            </div>

            <div v-if="payment.method.slug === 'stripe'">
            <div class="row table-row">
              <h4>Status</h4>
              {{ payment.payment.token.charge.status }}
            </div>
              <div class="row table-row">

                <h4>Card</h4>
                {{ payment.payment.token.card }}
              </div>
              <div class="row table-row">
                <h4>Source</h4>
                {{ payment.payment.charge.source }}
              </div>
              <div class="row table-row">
                <h4>Outcome</h4>
                {{ payment.payment.charge.outcome }}
              </div>
            </div>
            <div class="row table-row">
              <h4>All</h4>
              {{ payment.payment }}
            </div>

            <div class="row">
              <div class="col-6 col-xs-6 margintop">
              </div>
              <div class="col-6 col-xs-6 text-right pull-right invoice-total">
                <!--                <p>Subtotal : {{ order.sub_total | toCurrency }}</p>-->
                <p>Total : {{ payment.amount | toCurrency }} </p>
              </div>
            </div>

            <div class="row">
              <div class="col-6 col-xs-6 margintop">
                <p class="lead marginbottom">THANK YOU!</p>
                <button class="btn btn-success" id="invoice-print"><i class="fa fa-print"></i> Print Receipt</button>
                &nbsp;&nbsp;
                <button class="btn btn-danger"><i class="fa fa-envelope-o"></i> Mail Invoice</button>
              </div>
              <div class="col-6 col-xs-6 text-right pull-right">
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import global from "@/mixins/global";
export default {
  data() {
    return {
      payment: null,
      isLoading: false,
    };
  },
  mounted() {
    this.getPayment();
  },
  mixins: [global],
  computed: {
    isPaid() {
      return this.payment.status.name === 'paid';
    },
    isPending() {
      return this.payment.status.name === 'pending';
    }
  },
  methods: {
    getPayment() {
      this.isLoading = true;
      axios.get(`payments/${this.$route.params.id}`).then((response) => {
         this.payment = response.data;
         this.isLoading = false;
      }).catch((error) => {
        this.isLoading = false;
      });
    },

  },
};
</script>

<style>

/*Invoice*/
.invoice .top-left {
  color:#3ba0ff;
}

.invoice .top-right {
  text-align:right;
  padding-right:20px;
}

.invoice .table-row {
  margin-left:-15px;
  margin-right:-15px;
  margin-top:25px;
}

.invoice .payment-info {
  font-weight:500;
}

.invoice .table-row .table>thead {
  border-top:1px solid #ddd;
}

.invoice .table-row .table>thead>tr>th {
  border-bottom:none;
}

.invoice .table>tbody>tr>td {
  padding:8px 20px;
}

.invoice .invoice-total {
  margin-right:-10px;
  font-size:16px;
}

.invoice .last-row {
  border-bottom:1px solid #ddd;
}

.invoice-ribbon {
  width:105px;
  height:88px;
  overflow:hidden;
  position:absolute;
  top:-1px;
  left:14px;
}

.ribbon-inner {
  text-align:center;
  -webkit-transform:rotate(0deg);
  -moz-transform:rotate(0deg);
  -ms-transform:rotate(0deg);
  -o-transform:rotate(0deg);
  position:relative;
  padding:5px;
  left:-5px;
  top:11px;
  width:120px;
  background-color:#66c591;
  font-size:15px;
  color:#fff;
}

.ribbon-inner:before,.ribbon-inner:after {
  content:"";
  position:absolute;
}

.ribbon-inner:before {
  left:0;
}

.ribbon-inner:after {
  right:0;
}

@media(max-width:575px) {
  .invoice .top-left,.invoice .top-right,.invoice .payment-details {
    text-align:center;
  }

  .invoice .from,.invoice .to,.invoice .payment-details {
    float:none;
    width:100%;
    text-align:center;
    margin-bottom:25px;
  }

  .invoice p.lead,.invoice .from p.lead,.invoice .to p.lead,.invoice .payment-details p.lead {
    font-size:22px;
  }

  .invoice .btn {
    margin-top:10px;
  }
}

@media print {
  .invoice {
    width:900px;
    height:800px;
  }
}
</style>